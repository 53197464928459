import { useState, useCallback } from 'react'
import { useDispatch, useMappedState } from 'redux-react-hook'
import { selectorLogin } from 'store/login'
import * as actions from 'store/login/actions'

const useLogin = () => {
  const { loggingIn, loggedIn, errorMessage } = useMappedState(
    useCallback(selectorLogin),
  )

  const dispatch = useDispatch()
  const login = useCallback(
    (username, password) => dispatch(actions.login(username, password)),
    [dispatch],
  )
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [submitted, setSubmitted] = useState(false)

  return {
    login,
    username,
    setUsername,
    password,
    setPassword,
    submitted,
    setSubmitted,
    loggingIn,
    loggedIn,
    errorMessage,
  }
}

export { useLogin }
