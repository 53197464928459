import { css, mq } from '@creditas/stylitas'
import { utilities } from './utilities'

const box = ({
  theme,
  boxSizing = 'border-box',
  shadow,
  border,
  display,
  flexDirection,
  alignItems,
  justifyContent,
  height,
  backgroundColor,
  radius,
  padding,
  margin,
  marginBottom,
  marginTop,
  maxWidth = '100%',
  width = '100%',
  textAlign = 'left',
}) =>
  css`
    ${mq({
      boxSizing,
      display,
      flexDirection,
      justifyContent,
      alignItems,
      height,
      padding,
      maxWidth,
      width,
      textAlign,
    })}

    ${margin && utilities.margin.all(margin)};
    ${marginBottom && utilities.margin.bottom(marginBottom)};
    ${marginTop && utilities.margin.top(marginTop)};
    ${shadow && utilities.surface.shadow({ theme })};
    ${radius && utilities.radius[radius]({ theme })};
    ${border && utilities.border[border]({ theme })};
    ${border && `border-color: ${theme.bColorneutral03}`};
    ${backgroundColor && `background-color: ${theme.bColorneutral01}`}
  `

export { box }
