import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { LogoHorizontalPrincipal } from '@creditas/brand-icons/dist'
import { Typography } from '@creditas/typography'
import { Box } from 'components/Box'
import { LoginForm } from 'components/Login/LoginForm'
import { useMappedState } from 'redux-react-hook'
import { navigate } from 'gatsby-link'
import { selectorLogin } from 'store/login'
import { Loading } from 'components/Loading'

const Login = () => {
  const { t } = useTranslation('login')
  const [isNavigating, setIsNavigating] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { loggedIn } = useMappedState(useCallback(selectorLogin))

  useEffect(() => {
    if (loggedIn) {
      navigate('/')
      setIsNavigating(true)
    }

    setIsLoading(false)
  }, [loggedIn])

  return (
    <section>
      {isLoading || isNavigating ? (
        <Loading />
      ) : (
        <Box maxWidth="500px" margin="72px auto" textAlign="center">
          <LogoHorizontalPrincipal data-testid="xpto" />
          <Typography variant="textSupport">{t('title')}</Typography>

          <LoginForm />
        </Box>
      )}
    </section>
  )
}

export default Login
