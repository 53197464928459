import { css } from '@creditas/stylitas'

const loading = ({ absolute = true }) =>
  css`
    ${absolute &&
      `
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
    `}
  `

export { loading }
