/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { InputField } from '@creditas/form-elements'
import { styled } from '@creditas/stylitas'
import { Button } from '@creditas/button'
import { useToaster } from '@creditas/toaster'
import { useLogin } from './useLogin.hook'

const Form = styled.form`
  padding: 20px 0;
`

const LoginForm = () => {
  const { push } = useToaster()

  const {
    loggingIn,
    username,
    setUsername,
    password,
    setPassword,
    login,
    submitted,
    setSubmitted,
    errorMessage,
  } = useLogin()

  useEffect(() => {
    if (errorMessage) {
      push(errorMessage, { level: 'danger' })
    }
  }, [errorMessage])

  const handleSubmit = e => {
    e.preventDefault()

    setSubmitted(true)
    if (username && password) {
      login(username, password)
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <InputField
        name="username"
        type="text"
        width="100%"
        label="email"
        style={{ 'box-sizing': 'border-box' }}
        error={submitted && !username}
        onChange={e => setUsername(e.target.value)}
      />
      <InputField
        name="password"
        type="password"
        label="password"
        width="100%"
        style={{ 'box-sizing': 'border-box' }}
        error={submitted && !password}
        onChange={e => setPassword(e.target.value)}
      />

      <Button fullWidth isLoading={false} disabled={loggingIn}>
        Entrar
      </Button>
    </Form>
  )
}

export { LoginForm }
