import { css, utilities as utilitiesLib } from '@creditas/stylitas'

const marginSizes = {
  sm: '16px',
  md: '32px',
  lg: '48px',
  xlg: '64px',
}

const margin = {
  all: size => {
    const [t, r, b, l] = size.split(' ').map(i => marginSizes[i] || i)

    return css`
      margin: ${t} ${r} ${b} ${l};
    `
  },
  top: size => css`
    margin-top: ${marginSizes[size] || size};
  `,
  bottom: size => css`
    margin-bottom: ${marginSizes[size] || size};
  `,
  left: size => css`
    margin-left: ${marginSizes[size] || size};
  `,
  rigth: size => css`
    margin-right: ${marginSizes[size] || size};
  `,
}

const utilities = {
  ...utilitiesLib,
  margin,
}

export { utilities }
