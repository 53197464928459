import React from 'react'
import i18n from 'i18next'
import { string } from 'prop-types'
import { styled } from '@creditas/stylitas'
import { Spinner } from '@creditas/spinner'
import { loading } from './loading.styles'

const propTypes = {
  message: string,
}

const StyledSpinner = styled(Spinner)`
  ${loading}
`

const Loading = ({ message = i18n.t('commons:phrases.loading'), ...props }) => {
  return <StyledSpinner message={message} {...props} />
}

Loading.propTypes = propTypes
Loading.displayName = 'Loading'

export { Loading }
